import {DrupalTaxonomyTermSubMenu, DrupalTaxonomyTermSubMenuItem} from "../../types/hygiena-types";
import classNames from "classnames";
import {useContext, useEffect, useState, MouseEvent, FocusEvent} from "react";
import {Link} from "../atoms/link";
import {DrupalTaxonomyTerm} from "next-drupal";
import {MediaImage} from "../templates/media/media--image";
import {BlurMapContext} from "../../context/blur-map-context";
import Image from "next/image";
import {HERO_PLACE_HOLDER_URL, LARGE_IMAGE_STYLE} from "../../types/image-styles";
import {IconType, ImageIcon} from "../atoms/icon";
import {EmptyNavMenuContext} from "../../context/empty-nav-menu-context";
import {absoluteURL} from "../../lib/absolute-url";

/**
 * Checks if the child path is active or not.
 * @param item
 */
function pathIsActive(item, activePath, slice = 0) {
  if (item?.path?.alias === activePath) return true;
  try {
    // If the item is a product path, or similar, pop the last item and check the path.
    let pathItems = activePath?.split("/");
    if (pathItems?.length) {
      if (slice) pathItems = pathItems.slice(0, slice);
      else pathItems.pop();
      return item?.path?.alias === pathItems.join("/");
    }
    return false;
  }
  catch (error) {
    return false;
  }
}

/**
 * Checks if the utility menu child path is active or not.
 * @param item
 */
function utilityPathIsActive(item, activePath) {
  if (item?.path?.alias === activePath) return true;
  try {
    // The utility menu is always top level, so always check top level.
    const pathItems = activePath?.split("/")?.filter(val => val?.length);
    if (pathItems?.length) {
      return item?.path?.alias === `/${pathItems.shift()}`;
    }
    return false;
  }
  catch (error) {
    return false;
  }
}

function EmptyNavigationPadMenuItem({title, url, icon} :{title: string, url: string, icon?: string}) {
  const [hover, setHover] = useState<boolean>();
  return (
    <div className={classNames("h-auto text-center mx-auto text-sm font-normal max-w-[90px]", {"text-primary": hover})} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Link href={url ?? "/"} className="top-nav-link" prefetch={false}>
        <div className="h-[40px] relative">
          {icon && (
            <>
              <ImageIcon type={icon as IconType} className={classNames("absolute left-0 right-0 duration-200", {"opacity-0": !hover})} color={"primary"} width={icon === "search" ? 25 : 35} height={35} alt={title} style={{margin: "auto", maxHeight: "40px", width: "auto"}}/>
              <ImageIcon type={icon as IconType} className={classNames("absolute left-0 right-0 duration-200", {"opacity-0": hover})} color={"dark-gray"} width={icon === "search" ? 25 : 35} height={35} alt={title} style={{margin: "auto", maxHeight: "40px", width: "auto"}}/>
            </>
          )}
        </div>
        <span className="pt-2">{title}</span>
      </Link>
    </div>
  )
}

function EmptyNavigationPad() {
  const emptyNavMenu = useContext(EmptyNavMenuContext);
  return (
    <>
      {emptyNavMenu?.length ? (
        <div className="w-full flex-row flex lg:min-h-[520px] justify-center items-center pb-[100px] max-md:hidden" role="presentation">
          <div className="grid grid-cols-2 h-[100px] text-center gap-12 text-hygienaDarkerGray menu-nav-pad relative justify-center items-start">
            {emptyNavMenu.map(item => {
              const url = item?.field_menu_id ? `${item?.url}#${item.field_menu_id}` : item.url;
              return (
                <EmptyNavigationPadMenuItem title={item?.title} url={url} icon={item?.field_menu_icon?.name} key={`empty-menu-item--${item?.id}`}/>
              )
            })}
          </div>
        </div>
      ): <></>}
    </>
  )
}

/**
 * Returns a menu image placeholder.
 *
 * @param altText
 * @constructor
 */
export function PlaceHolderMenuImage({altText}: {altText?: string}) {
  const blurMap = useContext(BlurMapContext);

  return (
    <Image
      src={absoluteURL(HERO_PLACE_HOLDER_URL)}
      blurDataURL={blurMap?.hero_placeholder?.base64}
      placeholder={blurMap?.hero_placeholder?.base64 ? "blur" : "empty"}
      width={200}
      height={200}
      className={"absolute inset-0 w-full h-full"} style={{objectFit: "cover", height: "100%", width: "100%"}}
      alt={altText ?? "Hygiena"}/>
  );
}

/**
 * The sub menu second item.
 *
 * @param child
 *   The child.
 * @param itemCount
 *   The item count.
 * @param active
 *   Whether or not active.
 * @param item
 *   The item.
 * @param callback
 *   The callback.
 * @param index
 *   The index.
 * @param activePath
 *   The active path.
 * @constructor
 */
function SubMenuSecondItem({child, itemCount, active, item, callback, index, activePath, squash}: {
  child: DrupalTaxonomyTermSubMenuItem,
  active?: string,
  callback: Function,
  itemCount: number,
  item: DrupalTaxonomyTermSubMenuItem,
  index: number,
  activePath?: string,
  squash?: boolean,
}) {
  return (
    <li
      key={child.id}
      className={classNames(
        "flex w-full relative overflow-hidden scale-image-hover",
        {"min-h-[165px]": !squash && itemCount <= 9},
        {"min-h-[100px]": !squash && itemCount > 9},
        {"min-h-[75px]": squash},
      )}>
      {active === item?.id && (
        <>
          {child?.image ? (
            <MediaImage
              as={"image"}
              key={`menu-image--${child.image.id}`}
              blurDataURL="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiPjxyZWN0IGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiIGZpbGw9IiNDQ0NDQ0MiLz48L3N2Zz4="
              placeholder="blur"
              imageStyle={LARGE_IMAGE_STYLE}
              alt={child?.name ?? ""}
              media={child.image}
              sizes={itemCount <= 9 ? "275px" : "200px"}
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
                position: "absolute",
                transform: "scale(1.02)"
              }}
              className="object-fit w-full absolute h-full max-h-full transition-all duration-1000"
            />
          ) : <PlaceHolderMenuImage/>}
        </>
      )}
      <Link
        role="link"
        onClick={() => callback(false)}
        prefetch={false}
        onBlur={(event) => {
          // On tab/blur, focus the original parent menu item if the submenu item is the first or last
          // element.
          const parent = document.getElementById(`menu-item--${item?.id}`);
          if (!parent) return;
          if (event?.target?.classList.contains("first") || event?.target?.classList.contains("last")) {
            if (event.relatedTarget?.classList.contains("submenu-item")) return;
            if (parent.classList.contains("last")) return;
            parent.focus();
          }
        }}
        href={child?.altLink ?? child?.path?.alias ?? "/"}
        className={classNames(
          "top-nav-link hover:text-white focus:text-white w-full z-10 relative flex items-end submenu-item",
          {
            "first": index === 0,
            "last": index + 1 === item?.children?.length,
            "text-white": pathIsActive(child, activePath),
          }
        )}>
        <div
          className="relative py-2 px-1 tracking-tight bottom-0 w-full bg-hygienaLight/60 backdrop-blur-md submenu-image-card"
          role="presentation">
          <>
            {child?.displayTitle ? <span className="menu-italic"
                                         dangerouslySetInnerHTML={{__html: child.displayTitle}}/> : <>{child.name}</>}
          </>
        </div>
      </Link>
    </li>
  )
}

/**
 * Renders out the second depth of the submenu.
 *
 * @param submenuItems
 *   The submenu items.
 * @param active
 *   Whether the menu is active.
 * @param callback
 *   The menu visibility callback.
 * @param activePath
 *   The active path.
 * @param useFirstCard
 *   If no child items exist, use the parent item.
 * @constructor
 */
export function SubMenuSecond({submenuItems, active, callback, activePath, useFirstCard}: {
  submenuItems: DrupalTaxonomyTermSubMenuItem[],
  active?: string,
  callback: Function,
  activePath?: string,
  useFirstCard?: boolean,
}) {

  return (
    <>
      {submenuItems?.length ? (
        submenuItems.map(item => {
          let children = item?.children ?? [];
          if (!children?.length && useFirstCard) children = [item];
          const itemCount = children?.length ?? 0;
          let squash = false;
          for (let child of children) {
            if (child?.children?.length) {
              squash = true;
              break;
            }
          }

          if (itemCount) {
            return (
              <div
                role="presentation"
                className={classNames(
                  "flex-grow max-lg:hidden lg:overflow-auto",
                  {"hidden": active !== item?.id}
                )}
                key={item.id}>
                <ul id={`media-menu--${item.id}`} className={classNames(
                  "grid gap-1 text-sm",
                  {"grid-cols-3": itemCount <= 9},
                  {"grid-cols-4": itemCount > 9 && itemCount < 20},
                  {"grid-cols-5": itemCount >= 20}
                )}>
                  {children && children.map((child, index) => (
                    <>
                      {child?.children?.length ? (
                        <li key={child.id} className={classNames("mb-4",
                          {"grid-col-3": itemCount <= 9},
                          {"grid-col-4": itemCount > 9 && itemCount < 20},
                          {"grid-col-5": itemCount >= 20},
                          {"mb-8": children?.[index + 1] && !children[index + 1]?.children?.length},
                        )}>
                          <div className="pb-2 text-xl">{child.name}</div>
                          <ul id={`media-menu--${item.id}`} className={classNames(
                            "grid gap-1 text-sm",
                            {"grid-cols-3": child.children.length <= 9},
                            {"grid-cols-4": child.children.length > 9 && child.children.length < 20},
                            {"grid-cols-5": child.children.length >= 20},
                          )}>
                            {child.children.map((subchild, index) => (
                              <SubMenuSecondItem key={subchild.id} child={subchild} callback={callback} itemCount={itemCount} item={item}
                                                 index={index} active={active} activePath={activePath} squash={true}/>
                            ))}
                          </ul>
                        </li>
                        ) : (
                          <SubMenuSecondItem child={child} callback={callback} itemCount={itemCount} item={item}
                                             index={index} active={active} activePath={activePath} squash={squash}/>
                    )}
                </>
                ))}
              </ul>
          </div>
            )
          }
          else {
            return (
              <div role="presentation" className={classNames(
                "flex-grow max-lg:hidden",
              {"hidden": active !== item?.id}
              )} key={item.id}></div>
            )
          }
        })
      ): <></>}
    </>
  )
}

/**
 * Renders out the first depth of the submenu recursively, this renders out
 * first level items vertically, with second level items to the right in a
 * separate container.
 *
 * @param submenuItems
 *   The submenu items.
 * @param callback
 *   The set menu visible callback.
 * @constructor
 */
export function SubMenuTwoLevel({submenuItems, callback, utilityItems, activePath, visible}: {
  submenuItems: DrupalTaxonomyTermSubMenuItem[],
  callback: Function,
  utilityItems?: DrupalTaxonomyTerm[],
  activePath?: string,
  visible: boolean,
}) {
  // Use the first menu item as the default active on load.
  const [active, setActive] = useState<string>(submenuItems?.length ? submenuItems?.[0]?.id : undefined);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>(undefined);

  /**
   * Update the active child after a slight delay.
   *
   * @param childId
   */
  function updateActive(event: MouseEvent<HTMLAnchorElement>|FocusEvent, childId?: string) {
    if (!childId) return;
    const timeout = setTimeout(() => {
      setActive(childId);
    }, 50);
    setHoverTimeout(timeout);
  }

  /**
   * Cancels the active timeout.
   */
  function cancelUpdateActive() {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setTimeout(undefined);
    }
  }

  function focusThirdLevelMenu(id: string) {
    if (typeof window === "undefined") return;
    const thirdLevelMenu = document.querySelector(`#media-menu--${id} a:first-of-type`) as HTMLDivElement;
    if (thirdLevelMenu) thirdLevelMenu.focus();
  }

  useEffect(() => {
    const activeItems = submenuItems?.filter(item => pathIsActive(item, activePath));
    if (activeItems?.length) setActive(activeItems[0]?.id ?? undefined);
    else setActive(submenuItems?.length ? submenuItems?.[0]?.id : undefined);
  }, [visible]);

  return (
    <>
      {submenuItems?.length ? (
        <div className="w-full flex-row flex gap-6 h-full" role="presentation">
          <ul className={classNames(
            "pt-28 flex flex-col gap-2",
            "max-lg:w-full lg:w-[275px] lg:flex-shrink-0 lg:justify-center",
            "lg:border-r lg:pr-6 lg:border-r-white lg:w-4/13 lg:pt-0",
          )}>
            {submenuItems.map((item, index) => (
              <li key={item.id}>
                <Link
                  id={`menu-item--${item.id}`}
                  prefetch={false}
                  onClick={(event) => {
                    if (event.detail === 1) callback(false)
                    else event.preventDefault();
                  }}
                  onKeyUp={event => {
                    if (event?.key === "Enter") {
                      event.preventDefault();
                      focusThirdLevelMenu(item.id);
                    }
                  }}
                  onMouseEnter={(event) => updateActive(event, item.id)}
                  onFocus={(event) => {
                    updateActive(event, item.id);
                  }}
                  role="link"
                  onMouseLeave={cancelUpdateActive}
                  title={item.name}
                  href={item?.altLink ?? item?.path?.alias ?? "/"}
                  className={classNames(
                    "top-nav-link text-lg lg:text-sm py-2 px-2 block transition-all ease-in duration-100 lg:break-all submenu-link",
                    {
                      "last": index + 1 === submenuItems?.length,
                      "max-lg:text-primary lg:bg-primary lg:text-white": active === item.id || pathIsActive(item, activePath),
                    },
                  )}>{item.name}</Link>
              </li>
            ))}
          </ul>
          <SubMenuSecond callback={callback} submenuItems={submenuItems} active={active} activePath={activePath} useFirstCard={true}/>
        </div>
      ): <></>}
    </>
  )
}

/**
 * Renders out the first depth of the submenu recursively, with the second level beneath the
 * first level items, and the third levels to the right in a separate container.
 *
 * @param submenuItems
 *   The submenu items.
 * @param callback
 *   The set menu visible callback.
 * @param utilityItems
 *   The utility menu items.
 * @param activePath
 *   The active path.
 * @param visible
 *   Visibility option.
 * @param collapse
 *   Will combine all items together into one menu.
 * @constructor
 */
export function SubMenuThreeLevel({submenuItems, callback, utilityItems, activePath, visible, collapse}: {
  submenuItems: DrupalTaxonomyTermSubMenuItem[],
  callback: Function,
  utilityItems?: DrupalTaxonomyTerm[],
  activePath?: string,
  visible: boolean,
  collapse?: boolean,
}) {
  const [active, setActive] = useState<string>();
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>(undefined);
  let thirdLevelItems = [] as DrupalTaxonomyTermSubMenuItem[]
  let weighted = 0;
  let activeChild = "";

  for (const item of submenuItems) {
    if (item?.children?.length) {
      // Sort the second level items.
      weighted = item.children.filter(child => child.weight !== 0)?.length;
      if (weighted) {
        item.children = item.children.sort((a, b) => a?.weight - b?.weight);
      }
      else {
        item.children = item.children.sort((a, b) => a.name?.localeCompare(b?.name));
      }
      thirdLevelItems = thirdLevelItems.concat(item.children);
    }
  }

  if (collapse) {
    let tempItems = [];
    submenuItems?.forEach((submenuItem) => {
      submenuItem?.children?.forEach((item) => tempItems.push(item));
    });
    tempItems.sort((a, b) => a?.name?.localeCompare(b?.name ?? ''));
    // @ts-ignore
    submenuItems = [{
      id: submenuItems[0].id,
      name: submenuItems[0].name,
      children: tempItems,
    }];
  }

  /**
   * Update the active child after a slight delay.
   *
   * @param event
   * @param childId
   */
  function updateActive(event: MouseEvent<HTMLAnchorElement>|FocusEvent, childId?: string) {
    if (!childId) return;
    const timeout = setTimeout(() => {
      setActive(childId);
    }, 50);
    setHoverTimeout(timeout);
  }

  /**
   * Cancels the active timeout.
   */
  function cancelUpdateActive() {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setTimeout(undefined);
    }
  }

  function focusThirdLevelMenu(id: string) {
    if (typeof window === "undefined") return;
    const thirdLevelMenu = document.querySelector(`#media-menu--${id} a:first-of-type`) as HTMLDivElement;
    if (thirdLevelMenu) thirdLevelMenu.focus();
  }

  useEffect(() => {
    let items: DrupalTaxonomyTermSubMenuItem[] = [];
    for (const item of submenuItems) {
      if (item?.children?.length) items = items.concat(item.children);
    }
    const activeItems = items?.filter(item => pathIsActive(item, activePath, 3));
    if (activeItems?.length) setActive(activeItems[0]?.id ?? undefined);
    else setActive("");
  }, [visible]);

  if(submenuItems?.length) {
    for (const item of submenuItems) {
      if (item?.children?.length) {
        for (const child of item.children) {
          if (pathIsActive(child, activePath)) {
            activeChild = child.id;
            break;
          }
        }
      }
      if (activeChild) break;
    }
  }

  return (
    <>
      {submenuItems?.length ? (
        <div className="w-full flex-row flex gap-6 lg:min-h-[520px]" role="presentation">
          <ul className={classNames(
            "flex flex-col pr-6 gap-1.5 pt-28 justify-center",
            "max-lg:w-full lg:w-[275px] lg:flex-shrink-0",
            "lg:border-r lg:border-r-white lg:pt-0"
          )}>
            {submenuItems.map(item => {
              const label = `submenu-item--${item?.id}`;
              return (
                <li key={item.id} className="pt-1 pb-4">
                  {!collapse && (
                    <Link
                      tabIndex={0}
                      id={label}
                      role="link"
                      prefetch={false}
                      title={item.name}
                      href={item?.altLink ?? item?.path?.alias ?? "/"}
                      onClick={() => callback(false)}
                      className={classNames(
                        "top-nav-link hover:text-primary text-2xl lg:text-lg text-primary",
                        {"text-primary": active === item.id},
                      )}>{item.name}</Link>
                  )}
                  {item?.children?.length ? (
                    <ul className="pl-0 mt-2" aria-labelledby={label}>
                      {item.children.map((child, index) => (
                        <li key={child.id} className="p-0 text-lg lg:text-sm">
                          <Link
                            id={`menu-item--${child.id}`}
                            tabIndex={0}
                            prefetch={false}
                            role="link"
                            href={child?.altLink ?? child?.path?.alias ?? "/"}
                            onClick={(event) => {
                              if (event.detail === 1) callback(false)
                              else event.preventDefault();
                            }}
                            title={child.name}
                            className={classNames(
                              "top-nav-link py-2 px-4 w-full block transition-all ease-in duration-100 submenu-link",
                              {
                                "last": index + 1 === item?.children?.length,
                                "max-lg:text-primary lg:text-white lg:bg-primary": active === child.id || pathIsActive(child, activePath)
                              },
                            )}
                            onKeyUp={event => {
                              if (event?.key === "Enter") {
                                event.preventDefault();
                                focusThirdLevelMenu(child.id);
                              }
                            }}
                            onMouseEnter={(event) => updateActive(event, child.id)}
                            onFocus={(event) => {
                              updateActive(event, child.id);
                            }}
                            onMouseLeave={cancelUpdateActive}>{child.name}</Link>
                        </li>
                      ))}
                    </ul>
                  ): <></>}
                </li>
              )
            })}
          </ul>
          <SubMenuSecond callback={callback} submenuItems={thirdLevelItems} active={active} activePath={activePath} useFirstCard={true}/>
          {!active && (
            <EmptyNavigationPad/>
          )}
        </div>
      ): <></>}
      {utilityItems.length ? (
        <div className="w-full flex-row max-lg:hidden lg:flex" role="presentation" onMouseEnter={() => setActive(activeChild)}>
          <ul className="flex flex-row gap-8 flex-wrap pt-2 px-0 justify-between w-full">
            {utilityItems.map(item => (
              <li key={`utility--${item.id}`}>
                <Link role="link" prefetch={false} title={item.name} className={classNames(
                  "top-nav-link p-2 w-full hover:text-white hover:bg-primary",
                  {"text-white bg-primary": utilityPathIsActive(item, activePath)}
                )}
                  href={item?.altLink ?? item?.path?.alias ?? "/"} onClick={() => callback(false)}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      ): <></>}
    </>
  )
}

/**
 * Renders out a sub-menu in a mega-menu like fashion, handles specificity, depending on taxonomy vocabulary type.
 *
 * @param submenu
 *   The submenu object,
 * @param visible
 *   Whether the menu is visible or not.
 * @param callback
 *   The set menu visible callback.
 * @param activeSubmenu
 *   The active submenu.
 * @param setActiveSubmenu
 *   A callback to set the active submenu.
 * @param utilityItems
 *   The utility menu items.
 * @param activePath
 *   The active url path.
 *
 * @constructor
 */
export function SubMenu({
  submenu,
  visible,
  callback,
  activeSubmenu,
  setActiveSubmenu,
  utilityItems,
  activePath
}: {
  submenu: DrupalTaxonomyTermSubMenu,
  visible: boolean,
  callback: Function,
  activeSubmenu?: boolean,
  setActiveSubmenu: Function,
  utilityItems: DrupalTaxonomyTerm[],
  activePath?:string,
}) {
  const taxonomyMenuType = submenu?.menu?.resourceIdObjMeta?.drupal_internal__target_id;
  const weighted = submenu?.items?.length
    ? submenu.items.filter(item => item.weight !== 0)?.length : 0;

  if (!submenu?.items?.length) return <></>
  if (weighted) {
    submenu.items = submenu.items.sort((a, b) => a?.weight - b?.weight);
  }
  else {
    submenu.items = submenu.items.sort((a, b) =>  a?.name.localeCompare(b?.name));
  }

  return (
    <div role="presentation" className={classNames(
      "container absolute w-full right-0 left-0 mx-auto max-lg:bg-white px-8 z-10 transition-all",
      "ease-out max-lg:duration-500 lg:duration-50 flex gap-4 flex-col drop-shadow bg-hygienaLight/80 backdrop-blur-sm",
      "max-lg:max-h-[none] max-lg:top-0 h-full",
      {
        "max-lg:left-[100%]": !activeSubmenu,
        "!max-lg:left-0": activeSubmenu,
        "lg:opacity-0 overflow-hidden !lg:duration-[0s] lg:max-h-0 lg:min-h-0 hidden": !visible,
        "transition-all lg:duration-50 lg:py-8 lg:opacity-1 lg:max-h-[90vh] overflow-auto z-50": visible,
        "lg:min-h-[635px]": taxonomyMenuType === "product_type",
        "lg:min-h-[476px]": taxonomyMenuType !== "product_type",
      },
    )}>
      <div
        onClick={() => setActiveSubmenu("")}
        role="presentation"
        className={classNames(
        "absolute lg:hidden top-8 right-6 text-2xl cursor-pointer",
        )}>
        <div className="h-1 w-8 bg-primary rounded-md rotate-45 absolute right-0 cursor-pointer" role="presentation" />
        <div className="h-1 w-8 bg-primary rounded-md -rotate-45 absolute right-0 cursor-pointer" role="presentation" />
      </div>
      {taxonomyMenuType === "product_type" && (
        <SubMenuThreeLevel visible={visible} callback={callback} submenuItems={submenu.items} utilityItems={utilityItems} activePath={activePath} collapse={true} />
      )}
      {taxonomyMenuType !== "product_type" && (
        <SubMenuTwoLevel visible={visible} callback={callback} submenuItems={submenu.items} utilityItems={utilityItems} activePath={activePath} />
      )}
    </div>
  )
}


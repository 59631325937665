import {ImageIcon} from "../atoms/icon";
import classNames from "classnames";
import {useContext, useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import {LoadingSpinner} from "../atoms/loading-spinner";
import {Dictionary} from "../../types/hygiena-types";
import {DictionaryContext} from "../../context/dictionary-context";

export function WidgetSearch({callback, active, setActive, loading, searchSubmitRef}): JSX.Element {
  const router = useRouter();
  const ready = useRef(false);
  const ref = useRef<HTMLInputElement>();
  const [searchHovering, setSearchHovering] = useState<boolean>();
  const [searchText, setSearchText] = useState("");
  const t = useContext<Dictionary>(DictionaryContext);
  if (searchSubmitRef && !searchSubmitRef?.current) searchSubmitRef.current = onReturn;

  useEffect(() => {
    if (callback) {
      if (!ready?.current) {
        ready.current = true;
        return;
      }
      const handler = setTimeout(() => {
        callback(searchText);
      }, 500);

      return () => {
        clearTimeout(handler);
      }
    }
  }, [searchText]);

  function onReturn(event, skip = false) {
    if (skip || (event?.key === "Enter" || event?.keyCode === "13") && event?.target?.value) {
      let path = "/search";
      const locale = router.locale;
      const defaultLocale = router.defaultLocale;
      if (locale != defaultLocale) {
        path = "/" + locale + path;
      }

      router.push({pathname: locale === defaultLocale ? path : `/${locale}${path}`, query: {s: ref?.current?.value}});
      setActive(false);
      setSearchText("");
    }
  }

  return (
    <>
      <div className="h-6 w-6 relative">
        <ImageIcon
          role={"button"}
          aria-label={`${t?.all?.search}`}
          tabIndex={0}
          type="search"
          color={"primary"}
          className={classNames("header-search cursor-pointer hover:opacity-75 transition-all duration-500 absolute", {"opacity-0": searchHovering})}
          width={50}
          height={50}
          alt={`${t?.all?.search}`}
          onMouseEnter={() => setSearchHovering(true)}
          onKeyUp={(event) => { if (event.key === "Enter") setActive(!active)}}
          onMouseLeave={() => setSearchHovering(false)}
          onClick={() => setActive(!active)}/>
        <ImageIcon
          role={"button"}
          aria-label={`${t?.all?.search}`}
          tabIndex={0}
          type="search"
          color={"black"}
          className={classNames("header-search cursor-pointer hover:opacity-75 transition-all duration-500 absolute", {"opacity-0": !searchHovering})}
          width={50}
          height={50}
          alt={`${t?.all?.search}`}
          onMouseEnter={() => setSearchHovering(true)}
          onKeyUp={(event) => { if (event.key === "Enter") setActive(!active)}}
          onMouseLeave={() => setSearchHovering(false)}
          onClick={() => setActive(!active)}/>
      </div>
      <div className={classNames(
        "bg-white w-full absolute h-full top-0 overflow-hidden flex align-center items-center overflow-visible",
        {"opacity-0 -z-50": !active},
        {"!opacity-1 z-10": active}
      )}>
        {loading && <LoadingSpinner className="max-md:hidden absolute top-[29%] !opacity-100 !h-[35px] !w-[35px] ml-4 -left-12"/>}
        {active && (
          <input className="text-black text-4xl px-4 outline-0 w-full placeholder-gray-300" placeholder={`${t?.all?.start_typing}`} ref={ref} autoFocus={true}
                 onKeyUp={onReturn}
                 onChange={event => setSearchText(event?.target?.value)}></input>
        )}

        <ImageIcon
          type={"close"}
          color={"dark-gray"}
          role={"button"}
          aria-label={"Close search"}
          tabIndex={0}
          onClick={() => setActive(!active)}
          onKeyUp={(event) => { if (event.key === "Enter") setActive(false)}}
          className={classNames("cursor-pointer relative hover:invert hover:grayscale opacity-[.55] hover:opacity-75 right-1 transition-all duration-500")}
          width={20}
          height={20}
          alt={"Close search"}/>
      </div>
    </>
  );
}
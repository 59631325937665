import {HTMLProps} from "react";
import classNames from "classnames";

interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  headerType?: number,
  scrollRef?: any,
}

export function Heading({headerType, scrollRef, ...props}: HeadingProps) {
  const className = props.className ?? "";
  delete(props.className);
  return (
    <>
      {(headerType === 1) && <h1 className={classNames("text-[32px] md:text-5xl font-bold", className)} ref={scrollRef} {...props}>{props.children}</h1>}
      {(headerType === 2 || !headerType) && <h2 className={classNames("text-[28px] md:text-[40px] font-bold", className)} ref={scrollRef} {...props}>{props.children}</h2>}
      {headerType === 3 && <h3 className={classNames("text-xl max-md:font-semibold md:text-3xl", className)} ref={scrollRef} {...props}>{props.children}</h3>}
      {headerType === 4 && <h4 className={classNames("text-lg max-md:font-semibold md:text-2xl", className)} ref={scrollRef} {...props}>{props.children}</h4>}
      {headerType === 5 && <h5 className={classNames("text-xl", className)} ref={scrollRef} {...props}>{props.children}</h5>}
      {headerType === 6 && <h6 className={classNames("text-lg", className)} ref={scrollRef} {...props}>{props.children}</h6>}
    </>
  )
}

export function HeroHeading({...props}) {
  const className = props.className ?? "";
  delete(props.className);
  return (
    <Heading headerType={1} className={classNames("md:leading-hero max-md:leading-h-lg mb-6 text-black font-medium", className)}>
      {props.children}
    </Heading>
  )
}
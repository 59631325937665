import {DrupalNode} from "next-drupal";
import dynamic from "next/dynamic";
import {FormattedText} from "../../formatted-text";


const Banner = ({node}: {node: DrupalNode}) => {
  return (
    <div className="alert-banner sticky top-0 left-0 z-50 w-full px-2 py-1 text-center text-white bg-accent font-bold tracking-wide text-base md:text-xl">
      <FormattedText processed={node.body.processed}/>
    </div>
  );
}
export function NodeAlertBanner ({node}: {node: DrupalNode}) {
  return (
    <>
      {node?.field_alert_banner_link?.url ? (
        <a href={node.field_alert_banner_link.url}>
          <Banner node={node}/>
        </a>
      ): <Banner node={node}/>}
    </>
  )
}